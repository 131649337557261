import { FUNCTIONALITY_PERMISSION } from '@/utils/constants';

export default {
  computed: {
    functionalitiesAvailable () {
      return [...Object.keys(FUNCTIONALITY_PERMISSION)].filter(key => this.$auth.permissions.includes(FUNCTIONALITY_PERMISSION[key]));
    },
    functionalitiesNotAvailable () {
      return [...Object.keys(FUNCTIONALITY_PERMISSION)].filter(key => !this.$auth.permissions.includes(FUNCTIONALITY_PERMISSION[key]));
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated && !this.isAuthLoading;
    },
    isLoggedOut() {
      return !this.$auth.isAuthenticated && !this.isAuthLoading;
    },
    isAuthLoading() {
      return this.$auth.loading;
    },
    hasReqAccess () {
      return this.isLoggedIn && this.hasAccess(FUNCTIONALITY_PERMISSION.REQ);
    },
    hasIncorporationAccess () {
      return this.isLoggedIn && this.hasAccess(FUNCTIONALITY_PERMISSION.INCORPORATION);
    },
    user () {
      return this.$auth.user;
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout () {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    },
    hasAccess (perm) {
      return this.$auth.permissions.includes(perm);
    }
  }
};
