import { mapActions, mapGetters } from 'vuex'
import RevisionMessage from '@/components/RevisionMessage'

export default (skipSetValidator) => {
  return {
    components: {
      RevisionMessage
    },
    data () {
      return {
        errors: []
      }
    },
    created () {
      if (!skipSetValidator && !this.isViewModeReview)
        this.setValidator(this.validator)
    },
    computed: {
      ...mapGetters('revision', [
        'isFieldToRevise',
        'getFieldToReviseMessage'
      ]),
      ...mapGetters('viewMode', [
        'isViewModeCreate',
        'isViewModeReview',
        'isViewModeRevise'
      ])
    },
    methods: {
      ...mapActions('form', [
        'setValidator'
      ]),
      addError (error) {
        const errorIndex = this.errors.indexOf(error)
        if (errorIndex === -1) this.errors.push(error)
      },
      clearErrors () {
        this.errors = []
      },
      isFieldEditable (jsonPath) {
        return this.isViewModeCreate || (this.isViewModeRevise && this.isFieldToRevise(jsonPath))
      },
      getFieldLabel (field) {
        return this.$t(`form.field.${field}.label`)
      },
      getFieldPlaceholder (field) {
        return this.$t(`form.field.${field}.placeholder`)
      },
      getFieldType (field, index) {
        return this.errors.includes(field + (index ?? '')) ? 'is-danger' : ''
      },
      getFieldMessage (field, index, fallback = '') {
        return this.errors.includes(field + (index ?? '')) ? this.$t(`form.field.${field}.error`) : fallback
      },
      removeError (error) {
        const errorIndex = this.errors.indexOf(error)
        if (errorIndex !== -1) this.errors.splice(errorIndex, 1)
      },
      validator () {
        return new Promise((resolve, reject) => {
          this.clearErrors()

          if (this.validate)
            this.validate()

          if (this.errors.length === 0) {
            resolve()
          } else {
            reject(this.$options?.name)
          }
        })
      },
      validateMinLength (field, fieldValue, minLength) {
        if (!fieldValue || fieldValue.length < minLength)
          this.addError(field)
        else
          this.removeError(field)
      },
      validateNotEmpty (field, fieldValue) {
        this.validateMinLength(field, fieldValue, 1)
      },
      validateRegex (field, fieldValue, regex) {
        if (!regex.test(fieldValue))
          this.addError(field)
        else
          this.removeError(field)
      }
    }
  }
}
