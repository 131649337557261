<template>
  <div v-if="hasCondition">
    <b-message
        class="my-5"
        :type="`is-${getLevel}`"
    >
      <p v-html="getMessage"></p>
    </b-message>
  </div>
  <div v-else-if="shouldUpgradeForm">
    <b-message
        class="my-5"
        type="is-danger"
    >
      {{ $t('page.professionalOrder.needsNamedCorporation') }}
    </b-message>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProfessionalOrderCondition',
    props: {
      order: String,
      page: String
    },
    computed: {
      ...mapGetters('form', [
        'shouldUpgradeForm'
      ]),
      hasCondition () {
        return this.order && this.$te(this.getConditionKey)
      },
      getConditionKey () {
        return `professionalOrder.${this.order}.page.${this.page}`
      },
      getLevel () {
        return this.$te(this.getConditionKey + '.level') ? this.$t(this.getConditionKey + '.level') : 'warning'
      },
      getMessage () {
        return this.$te(this.getConditionKey + '.message') ? this.$t(this.getConditionKey + '.message') : this.$t(this.getConditionKey)
      }
    }
  }
</script>
