<template>
  <div
      class="content"
      :class="{ 'mb-0': !isViewModeCreate }"
  >
    <h1 v-if="isViewModeCreate">{{ title }}</h1>
    <div
        v-else
        class="is-space-between-flex"
    >
      <h3>{{ title }}</h3>
      <b-button
          v-if="isViewModeReview"
          type="is-text is-anchor"
          size="is-small"
          icon-right="edit"
          @click="$emit('edit')"
      >
        {{ $t('page.confirmation.edit') }}
      </b-button>
    </div>
    <slot v-if="isViewModeCreate"></slot>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'PageTitle',
    props: {
      title: String
    },
    computed: {
      ...mapGetters('viewMode', [
        'isViewModeCreate',
        'isViewModeReview'
      ])
    }
  }
</script>
