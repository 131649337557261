<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.professionalOrder.title')"
        @edit="$emit('edit')"
    >
    </page-title>
    <b-field :label="$t('form.field.professionalorderchoice.label')">
      <div v-if="isViewModeCreate">
        <b-radio
            v-model="model.isProfessional"
            :native-value="false"
            name="professional"
            @input="clearOrder"
        >
          {{ $t('form.field.professionalorderchoice.no') }}
        </b-radio>
        <b-radio
            v-model="model.isProfessional"
            :native-value="true"
            name="professional"
        >
          {{ $t('form.field.professionalorderchoice.yes') }}
        </b-radio>
      </div>
      <span v-else>{{ $t('form.field.professionalorderchoice.' + (model.isProfessional ? 'yes' : 'no')) }}</span>
    </b-field>
    <b-field
        v-if="model.isProfessional"
        :label="getFieldLabel('professionalorder')"
        :type="getFieldType('professionalorder')"
        :message="getFieldMessage('professionalorder')"
    >
      <b-select
          v-if="isViewModeCreate"
          v-model="model.order"
          :placeholder="getFieldPlaceholder('professionalorder')"
          class="page-professional-order__select"
      >
        <option
            v-for="order in professionalOrders"
            :value="order.id"
            :key="order.id"
        >
          {{ order.label }}
        </option>
      </b-select>
      <span v-else>{{ this.$t(`professionalOrder.${model.order}.name`) }}</span>
    </b-field>
    <professional-order-condition
        :order="order"
        page="professionalOrder"
    >
    </professional-order-condition>
  </fieldset>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import page from '@/mixins/page'
  import ProfessionalOrderCondition from '../form/ProfessionalOrderCondition'

  export default {
    name: 'PageProfessionalOrder',
    mixins: [
      page('professionalOrder')
    ],
    components: {
      ProfessionalOrderCondition
    },
    computed: {
      ...mapState('form', [
        'currentForm'
      ]),
      order () {
        return this.model?.order
      },
      professionalOrders () {
        return [
          'acupuncturist',
          'advocate',
          'agrologist',
          'architect',
          'audiologist',
          'bailiff',
          'certifiedhr',
          'certifiedinterpreter',
          'certifiedterminologist',
          'certifiedtranslator',
          'charteredadministrator',
          'charteredappraiser',
          'charteredprofessionalaccountant',
          'chemist',
          'chiropractor',
          'criminologist',
          'dentalhygienist',
          'dentaltechnician',
          'dentist',
          'denturologist',
          'dietician',
          'dispensingoptician',
          'engineer',
          'forestengineer',
          'geologist',
          'guidancecounsellor',
          'hearingaidacoustician',
          'landsurveyor',
          'marriagefamilytherapist',
          'medicalelectrophysiologytechnologist',
          'medicalimagerytechnologistnuclearmedecine',
          'medicalimagerytechnologistradiodiagnosis',
          'medicaltechnologist',
          'midwife',
          'notary',
          'nurse',
          'nursingassistant',
          'occupationaltherapist',
          'optometrist',
          'pharmacist',
          'physicalrehabilitationtherapist',
          'physician',
          'physiotherapist',
          'podiatrist',
          'professionaltechnologist',
          'psychoeducator',
          'psychologist',
          'radiationoncologytechnologist',
          'registeredrespiratorytherapist',
          'sexologist',
          'socialworker',
          'speechtherapist',
          'townplanner',
          'veterinarysurgeon'
        ]
            .map(po => {
              return { id: po, label: this.$t(`professionalOrder.${po}.name`) }
            })
            .sort((a, b) => ('' + a.label).localeCompare(b.label))
      }
    },
    methods: {
      ...mapActions('form', [
        'setExitEarly',
        'setNeedsUpgrade'
      ]),
      clearOrder (val) {
        if (!val) {
          this.model.order = undefined
        }
      },
      verifyOrderEligibility () {
        if (this.order && [
          'acupuncturist',
          'audiologist',
          'certifiedhr',
          'chemist',
          'criminologist',
          'dentalhygienist',
          'dentaltechnician',
          'dietician',
          'engineer',
          'forestengineer',
          'medicaltechnologist',
          'midwife',
          'nurse',
          'nursingassistant',
          'occupationaltherapist',
          'physiotherapist',
          'podiatrist',
          'professionaltechnologist',
          'sexologist',
          'socialworker',
          'speechtherapist',
          'townplanner'
        ].includes(this.order)) {
          this.setExitEarly(true)
        } else {
          this.setExitEarly(false)
        }
      },
      validateFormUpgrade () {
        if (this.order && ['QC_NUM', 'CA_NUM'].includes(this.currentForm) && [
          'charteredadministrator',
          'agrologist',
          'architect',
          'landsurveyor',
          'hearingaidacoustician',
          'advocate',
          'chiropractor',
          'charteredprofessionalaccountant',
          'dentist',
          'denturologist',
          'geologist',
          'bailiff',
          'notary',
          'pharmacist',
          'medicalelectrophysiologytechnologist',
          'medicalimagerytechnologistnuclearmedecine',
          'medicalimagerytechnologistradiodiagnosis',
          'certifiedtranslator',
          'certifiedterminologist',
          'certifiedinterpreter'
        ].includes(this.order)) {
          this.setNeedsUpgrade(true)
        } else {
          this.setNeedsUpgrade(false)
        }
      },
      validate () {
        if (this.model.isProfessional && !this.order)
          this.addError('professionalorder')
      }
    },
    watch: {
      order () {
        this.verifyOrderEligibility()
        this.validateFormUpgrade()
        this.validate()
      }
    }
  }
</script>
