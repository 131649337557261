import { mapActions, mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';
import auth from './auth';
import validator from './validator';
import PageTitle from '@/components/page/common/PageTitle';

export default (pageKey) => {
  return {
    mixins: [
      auth,
      validator()
    ],
    components: {
      PageTitle
    },
    mounted () {
      if (this.isViewModeCreate) {
        this.focusFirst();
        this.$nextTick(() => {
          window.scrollTo(0, 0);
          document.getElementsByClassName('form-view-content-navigation').forEach(el => el?.scrollTo(0, 0));
        });
      }
    },
    data () {
      return {
        model: undefined
      };
    },
    computed: {
      ...mapState('form', {
        storedModel: 'model'
      }),
      ...mapGetters('viewMode', [
        'isViewModeCreate'
      ])
    },
    created () {
      if (this.isViewModeCreate) {
        this.setUpdator(this.updateStoredModel);
      }

      if (Array.isArray(pageKey)) {
        this.model = {};
        pageKey.forEach(pk => this.$set(this.model, pk, cloneDeep(this.storedModel[pk])));
      } else {
        this.model = cloneDeep(this.storedModel[pageKey]);
      }
    },
    methods: {
      ...mapActions('form', [
        'setUpdator',
        'updateModelPage'
      ]),
      focusFirst () {
        if (this.$el && this.$el.querySelector) {
          const input = this.$el.querySelector('.page-focus-first');
          if (input) input.focus();
        }
      },
      updateStoredModel () {
        return new Promise(resolve => {
          if (this.updateModelPartially) {
            this.updateModelPartially();
          }
          if (Array.isArray(pageKey)) {
            pageKey.forEach(pk => {
              this.updateModelPage({ pageKey: pk, pageModel: this.model[pk] });
            });
          } else {
            this.updateModelPage({ pageKey, pageModel: this.model });
          }
          resolve();
        });
      }
    }
  };
};
